import React, {useContext, useEffect, useState} from "react";
import {Button, Modal, Form, Input, Select, Checkbox, Steps, Radio, Space, Flex} from "antd";
import DeleteModal from "../ui/Modals/DeleteModal";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {Context} from "../../index";
import {Link} from "react-router-dom";

const ymFields = [
  {
    label: "ym:s:productID",
    value: "ym_s_productID",
  },
  {
    label: "ym:s:productName",
    value: "ym_s_productName",
  },
];

const ModalsIntegration = ({
  setPopoverVisible,
  setModalsVisible,
  modalsVisible,
  addIntegration,
  updateIntegration,
  deleteIntegration,
  integrationid,
  updateIntegrations,
  clickedRow,
  currentIntegration,
  setCurrentIntegration,
  feedsProject
}) => {
  const { store } = useContext(Context);
  const [form] = Form.useForm(); // Инициализация формы
  const [formEdit] = Form.useForm(); // Инициализация формы
  const [days, setDays] = React.useState();
  const [ecommerce, setEcommerce] = React.useState(false);
  // const [current, setCurrent] = useState(0);

  const [feedColumns, setFeedColumns] = useState([]);

  const [feedId, setFeedId] = useState(0);
  const [radioBtnValue, setRadioBtnValue] = useState(0);
  const [fieldsAssociationColumn, setFieldsAssociationColumn] = useState([{type: "select"}]);
  const [formAssociationColumnVisible, setFormAssociationColumnVisible] = useState(false);
  const [selectedFeedColumn, setSelectedFeedColumn] = useState(0);
  const [selectedYmField, setSelectedYmField] = useState("ym_s_productID");

  // Эффект для обновления формы при изменении clickedRow
  useEffect(() => {
    console.log(clickedRow);
    if (modalsVisible.editModalVisible) {
      // Обновляем значения формы, когда модальное окно становится видимым и clickedRow изменяется
      formEdit.setFieldsValue({
        name: clickedRow?.name,
        token: clickedRow?.data.api_key,
        feedUrl: clickedRow?.data.zoomos_feed,
        url: clickedRow?.data.url,
        meterNumber: clickedRow?.data.meter_number,
        period: clickedRow?.data.period,
        ecommerce: clickedRow?.data.ecommerce,
        feed: clickedRow?.data.feed,
        tagOnFeed: clickedRow?.data.tag_on_feed,
        fieldOnYm: clickedRow?.data.field_on_ym,
        metricGoals: clickedRow?.data.metric_goals,
      });
    }
  }, [clickedRow, modalsVisible.editModalVisible, formEdit, days]);

  const handleEditModalOk = (e) => {
    e.stopPropagation();
    const checkform = formEdit.getFieldsValue();

    // Функция для приведения значений к общему виду (замена null на пустую строку)
    const normalize = (value) => value === null ? '' : value;

    // Приводим значения к общему виду
    const formData = {
      name: normalize(checkform.name),
      token: normalize(checkform.token),
      feedUrl: normalize(checkform.feedUrl),
      url: normalize(checkform.url),
      meterNumber: normalize(checkform.meterNumber),
      period: normalize(checkform.period),
      ecommerce: normalize(checkform.ecommerce),
      feed: normalize(checkform.feed),
      tagOnFeed: normalize(checkform.tagOnFeed),
      fieldOnYm: normalize(checkform.fieldOnYm),
      metricGoals: normalize(checkform.metricGoals)
    };

    const originalData = {
      name: normalize(clickedRow?.name),
      token: normalize(clickedRow?.data.api_key),
      feedUrl: normalize(clickedRow?.data.zoomos_feed),
      url: normalize(clickedRow?.data.url),
      meterNumber: normalize(clickedRow?.data.meter_number),
      period: normalize(clickedRow?.data.period),
      ecommerce: normalize(clickedRow?.data.ecommerce),
      feed: normalize(clickedRow?.data.feed),
      tagOnFeed: normalize(clickedRow?.data.tag_on_feed),
      fieldOnYm: normalize(clickedRow?.data.field_on_ym),
      metricGoals: normalize(clickedRow?.data.metric_goals)
    };

    let shouldSubmit = false;

    if (clickedRow?.type === 1) {
      shouldSubmit = originalData.name !== formData.name ||
                     originalData.token !== formData.token ||
                     originalData.feedUrl !== formData.feedUrl;
    }

    if (clickedRow?.type === 2) {
      shouldSubmit = originalData.name !== formData.name ||
                     originalData.url !== formData.url;
    }

    if (clickedRow?.type === 3) {
      shouldSubmit = originalData.name !== formData.name ||
                     originalData.meterNumber !== formData.meterNumber ||
                     originalData.period !== formData.period ||
                     originalData.ecommerce !== formData.ecommerce ||
                     originalData.feed !== formData.feed ||
                     originalData.tagOnFeed !== formData.tagOnFeed ||
                     originalData.fieldOnYm !== formData.fieldOnYm ||
                     originalData.metricGoals !== formData.metricGoals;
    }

    if (shouldSubmit) {
      // console.log('submit');
      formEdit.submit(); // Сабмит формы
    }
    else{
      setModalsVisible((prev) => ({
        ...prev,
        editModalVisible: false,
      }));
    }
  };


  const handleEditModalCancel = (e) => {
    e.stopPropagation();
    formEdit.resetFields();
    setModalsVisible((prev) => ({
      ...prev,
      editModalVisible: false,
    }));
  };

  const handleDeleteModalOk = async () => {
    // Здесь может быть логика удаления элемента
    await deleteIntegration(clickedRow.key).then(() => {
      setModalsVisible((prev) => ({
        ...prev,
        deleteModalVisible: false,
      }));
      updateIntegrations();
    });
  };

  const setDeleteModalVisible = (state) => {
    setModalsVisible((prev) => ({
      ...prev,
      deleteModalVisible: state,
    }));
  };

  const handleOk = () => {
    form.submit(); // Сабмит формы

  };

  const handleCancel = () => {
    setCurrentIntegration(null)
    setModalsVisible((prev) => ({
      ...prev,
      isModalVisible: false,
    }));

    form.resetFields();
  };

  const onFinish = async (values) => {
    const data = {
      api_key: values.token,
      zoomos_feed: values.feedUrl || null,
      meter_number: values.meterNumber || null,
      period: values.period || null,
      ecommerce: ecommerce || null,
      feed: feedId || null,
      tag_on_feed: selectedFeedColumn || null,
      field_on_ym: selectedYmField || null,
      metric_goals: values.metricGoals || null,
    };

    const response = await addIntegration(integrationid, values.name, currentIntegration, data);
    if (response.status === 201) {
      setModalsVisible((prev) => ({
        ...prev,
        isModalVisible: false,
      }));
      updateIntegrations();
      form.resetFields();
      setCurrentIntegration(null)
    } else if (response.status === 422) {
      const currentFields = form.getFieldsValue();
      console.log(currentFields);
      form.setFields([
        ...Object.keys(currentFields).map(key => ({
          name: key,
          value: currentFields[key]
        })),
        {
          name: 'token',
          //errors: ['Такая интеграция уже добавлена!'],
          errors: [response.data.detail],
        },
      ]);
    }
  };

  const onFinishEdit = async (values) => {
    let data = {}
    if (currentIntegration === "1") {
      data = {
        api_key: values.token,
        zoomos_feed: values.feedUrl || null,
      };
    } else if (currentIntegration === "3") {
      data = {
        api_key: values.token,
        meter_number: values.meterNumber || null,
        period: values.period || null,
        ecommerce: ecommerce || null,
        feed: feedId || null,
        tag_on_feed: selectedFeedColumn || null,
        field_on_ym: selectedYmField || null,
        metric_goals: values.metricGoals || null,
      };
    } else {
      data = {
        url: values.url,
      };
    }

    const response = await updateIntegration(clickedRow.key, values.name, data, currentIntegration);

    if (response.status === 200) {
      setModalsVisible((prev) => ({
        ...prev,
        editModalVisible: false,
      }));
      updateIntegrations();
      formEdit.resetFields();
    } else if (response.status === 422) {
      const currentFields = formEdit.getFieldsValue();
      formEdit.setFields([
        ...Object.keys(currentFields).map(key => ({
          name: key,
          value: currentFields[key]
        })),
        {
          name: 'token',
          errors: ['Такая интеграция уже добавлена!'],
        },
      ]);
    }
  };

  const getFeedColumns = async () => {
    const response = await store.getFeedColumns(feedId);
    const preparedFeedColumns = response.data.map((el) => ({
      value: el.id,
      label: el.name,
    }));
    setFeedColumns(preparedFeedColumns);
  };

  useEffect(() => {
    if (feedId !== 0) {
      getFeedColumns();
    }
  }, [feedId]);

  const onChangeFeed = (value) => {
    setFeedId(value)
  }

  const onChangeEcommerce = (e) => {
    setEcommerce(e.target.checked);
    setRadioBtnValue(0);
    setFormAssociationColumnVisible(false);
  };

  const handleRadioBtn = (e) => {
    if (e.target.value === 0) {
      setFormAssociationColumnVisible(false);
    } else {
      setFormAssociationColumnVisible(true);
    }
    setRadioBtnValue(e.target.value);
    setFieldsAssociationColumn([{type: "select"}]);
    setSelectedFeedColumn(0);
    setSelectedYmField("ym_s_productID");
  };

  const onChangeFeedColumn = (value) => {
    console.log(value);
    setSelectedFeedColumn(value);
  };

  const onChangeYmField = (value) => {
    console.log(value);
    setSelectedYmField(value);
  };

  // const steps = [
  //   {
  //     title: "Настройка",
  //     content: (
  //     ),
  //   },
  //   {
  //     title: "Загрузка",
  //     content: (
  //         <div></div>
  //     ),
  //   },
  // ];

  // const items = steps.map((item) => ({
  //   key: item.title,
  //   title: item.title,
  // }));


  return (
    <>
      <Modal
        title="Добавить интеграцию"
        open={modalsVisible.isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Сохранить
          </Button>,
        ]}
      >
        <Form
            form={form}
            name="integrationForm"
            onFinish={onFinish}
            initialValues={{remember: false}}
            autoComplete="off"
            style={{marginTop: 20, width: 500}}
        >
          <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите название интеграции!",
                },
              ]}
          >
            <Input placeholder="Название интеграции"/>
          </Form.Item>
          <Form.Item
              name="token"
              rules={[
                {
                  required: true,
                  message: currentIntegration === "1" ? "Пожалуйста, введите API токен аккаунта!" : currentIntegration === "3" ? "Пожалуйста, введите токен Яндекс Метрики" : "Пожалуйста, введите ссылку на публичную таблицу в Google Sheets",
                },
              ]}
          >
            <Input
                placeholder={currentIntegration === "1" ? "API токен аккаунта" : currentIntegration === "3" ? "Токен Яндекс Метрики" : "Ссылка на публичную таблицу в Google Sheets"}/>
          </Form.Item>
          {currentIntegration === "3" &&
          <span>
              - Залогиньтесь в аккаунт, у которого есть доступ к нужному вам счетчику метрики<br/>
              - Перейдите по ссылке: <Link to={'https://oauth.yandex.ru/authorize?response_type=token&client_id=dbb281abcd134b1bb4c624748f03cffe'}
                                           target="_blank">
                  https://oauth.yandex.ru/authorize?response_type=token&client_id=dbb281abcd134b1bb4c624748f03cffe</Link><br/>
              - Полученный токен вставьте в поле "Токен Яндекс Метрики"<br/><br/>
            </span>
          }
          {currentIntegration === "1" && <Form.Item name="feedUrl">
            <Input placeholder="Ссылка на фид, используемый в zoomos"/>
          </Form.Item>}

          {currentIntegration === "3" &&
          <Form.Item
              name="meterNumber"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите номер счетчика!",
                },
              ]}>
            <Input placeholder="Номер счетчика Яндекс Метрики"/>
          </Form.Item>
          }
          {currentIntegration === "3" &&
          <Form.Item
              name="period"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите период первичных данных!",
                },
              ]}>
            <Select
                style={{width: 300}}
                placeholder="Выберите период первичных данных"
                onChange={(e) => setDays(e)}
                value={days}
                options={[{label: "последние 30 дней", value: 30}, {
                  label: "последние 90 дней",
                  value: 90
                }, {label: "последние 180 дней", value: 180}, {label: "последние 365 дней", value: 365}]}
            />
          </Form.Item>
          }
          {currentIntegration === "3" &&
          <Form.Item
              name="feed"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите фид!",
                },
              ]}>
            <Select
                style={{width: 300}}
                placeholder="Выберите фид"
                onChange={onChangeFeed}
                value={feedId === 0 ? "Выберите фид" : feedId}
                options={feedsProject}
            />
          </Form.Item>
          }
          {currentIntegration === "3" &&
          <Form.Item name="ecommerce" valuePropName="checked">
            <Checkbox
                onChange={onChangeEcommerce}
            >
              Ecommerce на сайте
            </Checkbox>
          </Form.Item>
          }
          {currentIntegration === "3" && ecommerce &&
          <div>
            <span>Выберите способ объединения даных фида и статистики метрики:</span>
            <Radio.Group onChange={handleRadioBtn} value={radioBtnValue}>
              <Space direction="vertical">
                <Radio value={0}>offer_id + ym:s:productID</Radio>
                <Radio value={1}>
                  Выбрать свой тег + Выбрать поле Яндекс Метрики
                </Radio>
              </Space>
            </Radio.Group>
            {formAssociationColumnVisible && (
                <>
                  {fieldsAssociationColumn.length !== 0 && (
                      <Flex
                          justify="space-between"
                          align="center"
                          style={{paddingTop: "10px"}}
                      >
                        <div>Тег в фиде &nbsp;&nbsp;</div>
                        <div style={{paddingLeft: "100px"}}>Поле метрики</div>
                        <div> </div>
                      </Flex>
                  )}
                  {fieldsAssociationColumn.map((field, index) => (
                      <Flex justify="space-between" key={index} align="baseline">
                        <Form.Item name="tagOnFeeds">
                          <Select
                              placeholder="Выберите тег в фиде"
                              // onChange={(value) =>
                              //     handleSelectedNewColumn(
                              //         value,
                              //         selectedNewColumns[index]?.value
                              //     )
                              // }
                              onChange={onChangeFeedColumn}
                              style={{
                                width: "100%",
                                minWidth: "215px",
                              }}
                              value={selectedFeedColumn[index]?.label}
                              //options={availableNewColumns}
                              options={feedColumns}
                          />
                        </Form.Item>
                        <Form.Item name="fieldOnYm">
                          <Select
                              placeholder="Выберите поле метрики"
                              // onChange={(value) =>
                              //     handleSelectedOldColumn(
                              //         value,
                              //         //index
                              //         selectedOldColumns[index]?.value
                              //     )
                              // }
                              onChange={onChangeYmField}
                              style={{
                                width: "100%",
                                minWidth: "215px",
                              }}
                              value={ymFields[index]?.label}
                              //options={availableOldColumns}
                              options={ymFields}
                          />
                        </Form.Item>
                      </Flex>
                  ))}
                </>
            )}
          </div>
          }
          {currentIntegration === "3" &&
          <Form.Item name="metricGoals" style={{paddingTop: "20px"}}>
            <Input placeholder="Введите номер цели"/>
          </Form.Item>
          }

        </Form>
      </Modal>
      <Modal
        title="Редактировать"
        open={modalsVisible.editModalVisible}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={handleEditModalOk}>
            Сохранить изменения
          </Button>,
        ]}
      >
        <Form
          form={formEdit}
          name="editIntegrationForm"
          onFinish={onFinishEdit}
          initialValues={{ remember: true }}
          autoComplete="off"
          style={{ marginTop: 20 }}
        >

          <Form.Item
            name="name"
            shouldUpdate
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название интеграции!",
              },
            ]}
          >
            <Input placeholder="Название интеграции" />
          </Form.Item>

          {clickedRow?.type === 1 &&  <> <Form.Item
            name="token"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите API токен аккаунта!",
              },
            ]}
          >
            <Input placeholder={clickedRow.type === 1?"API токен аккаунта":"Ссылка на публичную таблицу в Google Sheets"} />
          </Form.Item><Form.Item name="feedUrl">
            <Input placeholder="Ссылка на фид, используемый в zoomos" />
          </Form.Item></>}
          {clickedRow?.type === 2 &&  <> <Form.Item
            name="url"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите URL!",
              },
            ]}
          >
            <Input placeholder={clickedRow?.type === 1?"API токен аккаунта":"Ссылка на публичную таблицу в Google Sheets"} />
          </Form.Item></>}
        </Form>
      </Modal>

      <DeleteModal
        onConfirmDelete={handleDeleteModalOk}
        isModalDeleteOpen={modalsVisible.deleteModalVisible}
        setIsModalDeleteOpen={setDeleteModalVisible}
        title="Удалить интеграцию"
        text={`Вы уверены, что хотите удалить "${clickedRow?.name}" со всем содержимым?`}
      />
    </>
  );
};

export default ModalsIntegration;
