import {Button, Col, Flex, Row, message, Tooltip, Select} from "antd";
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';  // Для локализации на русский язык
import { CopyOutlined, SyncOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React, {useContext} from "react";
import {Context} from "../../index";

const { Option } = Select;

const ResizableTitle = ({ feed,handleRemoveFeed,feedupd,dashboard,dashboardUpdate,id }) => {
  const {store} = useContext(Context);
  const formattedDate = feedupd ? format(parseISO(feedupd), 'dd MMMM yyyy, HH:mm', {locale: ru}) : '';
  const [messageApi, contextHolder] = message.useMessage();
  const mess = (type, content) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };
  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(dashboard.feed);
      // Опционально: показать уведомление о том, что текст скопирован
      mess('success', 'URL скопирован в буфер обмена')
    } catch (err) {
      // Обработка ошибок, если копирование не удалось
      mess('error', 'Не удалось скопировать URL')
    }
  };

  const refreshFeedUrl = () => {
    mess('success', 'Обновлено')
    dashboardUpdate(id);
  };

  const intervals = ["24:00:00", "12:00:00", "04:00:00"];
  const [interval, setInterval] = React.useState(feed.interval);
  const [openSelectInterval, setOpenSelectInterval] = React.useState('');

  const handleInterval = (value) => {
    if (value.length !== 0) {
      setInterval(value[value.length - 1]);
    }
  };

  const handleApplyInterval = () => {
    store.updateFeed(feed.id, interval);
    setOpenSelectInterval(false);
  };

  return (
    <>
    {contextHolder}
       <Row className="feeddashboardtop">
        <Col span={14}>

          <Flex>

          {dashboard?.feed && (
            <div className="feeddashboardtop-item" style={{ marginBottom: 20 }}>
              <strong>Фид: </strong>
              {dashboard?.feed} <CopyOutlined onClick={handleCopyUrl} style={{ color: '#a3a3a3' }} />
            </div>
          )}
           {(feed.parent && !dashboard?.feed)  && (
            <div className="feeddashboardtop-item" style={{ marginBottom: 20 }}>
            <strong>Фид: </strong>
            В обработке <SyncOutlined onClick={refreshFeedUrl} style={{ color: '#a3a3a3' }} />
          </div>
            )}

            {(feed.parent && dashboard?.feed)  && (
              <a href={dashboard?.feed}
              download="feed.csv" rel="noreferrer" target="_blank" style={{marginLeft: 20}} className="link">
                Выгрузить в CSV
              </a>
            )}

          </Flex>

          <Flex>
            <div className="select-interval">
            {(!feed.parent && dashboard?.feed)  && (
                <div className="feeddashboardtop-item" style={{ marginBottom: 20 }}>
                  <strong>Автообновление &nbsp;</strong>
                  <Tooltip title="По умолчанию настроено автообновление фида каждые 24 часа" color="#4A5EFF" overlayStyle={{maxWidth: '280px', lineHeight: '18px'}}>
                    <InfoCircleOutlined />
                  </Tooltip>

                  <Select
                      mode="multiple"
                      placeholder="Значение"
                      value={interval}
                      open={openSelectInterval}
                      onChange={(value) => handleInterval(value)}
                      style={{ marginLeft: "10px", width: "180px", maxHeight: "40px"}}
                      showSearch={false}
                      allowClear={false}
                      //filterOption={filterOption}
                      onFocus={() => setOpenSelectInterval(true)}
                      //onSearch={() => setOpenSelectInterval(true)}
                      onBlur={() => setTimeout(() => {
                        setOpenSelectInterval(false);
                      }, 100)}
                      dropdownRender={(menu) => (
                          <div>
                            {menu}
                            <div
                                style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                            >
                              {/*<Input*/}
                              {/*    placeholder="Своё значение"*/}
                              {/*    style={{ flex: "auto" }}*/}
                              {/*    onChange={(e) =>*/}
                              {/*        handleConditionChange(index, "value", e.target.value)*/}
                              {/*    }*/}
                              {/*/>*/}

                              <a style={{ marginLeft: "auto", color: "#4A5EFF"}} onClick={handleApplyInterval}>Применить</a>
                            </div>
                          </div>
                      )}
                  >
                    {/*{columns.map((col) => (*/}
                    {/*    <Option key={col.value} value={col.value}>*/}
                    {/*      {col.label}*/}
                    {/*    </Option>*/}
                    {/*))}*/}

                    {intervals.map((interval) => (
                        <Option key={interval} value={interval}>
                          {interval === "24:00:00" ? "Каждые 24 часа" : interval === "12:00:00" ? "Каждые 12 часов" : "Каждые 4 часа"}
                        </Option>
                    ))}
                  </Select>
                </div>
            )}
            </div>
          </Flex>

          <Button onClick={handleRemoveFeed} type="primary" className="btn" style={{marginTop: 20}}>
            Удалить фид
          </Button>
        </Col>


        {feedupd &&
        <Col span={10}>
          <div className="feeddashboardcard">
            <div className="feeddashboardcard-title feeddashboardcard-title-smallgap">

              {/*{feed.parent ? 'Дата обновления фида на сервере Центр Фидов' : 'Дата обновления фида на сервере клиента'}*/}
              Дата обновления фида на сервере Центр Фидов
            </div>
            <div className="feeddashboardcard-content_time">
              {formattedDate}
            </div>
          </div>
        </Col>
        }
      </Row>
    </>
  );
};

export default ResizableTitle;
